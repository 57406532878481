import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import GatsbyImage from "common/components/GatsbyImage";
import Heading from "common/components/Heading";
import Container from "common/components/UI/Container";
import { useTranslation } from "react-i18next";
import { Tab, TabList, TabPanel } from "react-tabs";
import Masonry from "react-masonry-component";
import { SectionWrapper, SectionHeader } from "../visuella.style";
import {
  ReactTabs,
  DesignsWrapper,
  DesignItem,
  DesignItemInner,
} from "../Designs/designs.style";
import { arrowRight } from "react-icons-kit/feather/arrowRight";
import Link from "common/components/Link";
import { Icon } from "react-icons-kit";
import PropTypes from "prop-types";

const masonryOptions = {
  originTop: true,
};

const DesignDetails = ({ key }) => {
  const { t } = useTranslation();
  
  return (
    <SectionWrapper
      id="design"
      style={{
        "--paddingTop": "100px",
        "--paddingBottom": "60px",
      }}
    >
      <Container></Container>
    </SectionWrapper>
  );
};

DesignDetails.propTypes = {
  key: PropTypes.string,
};

DesignDetails.defaultProps = {
  key: "",
};

export default DesignDetails;
